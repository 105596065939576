/*
 *
 * MonitoredResources constants
 *
 */

export const GET_MONITORED_RESOURCES =
  'app/MonitoredResources/GET_MONITORED_RESOURCES'

export const SET_MONITORED_RESOURCES =
  'app/MonitoredResources/SET_MONITORED_RESOURCES'

export const SET_IMPORTANCE_MULTI =
  'app/MonitoredResources/SET_IMPORTANCE_MULTI'

export const SET_IMPORTANCE_MULTI_SUCCESS =
  'app/MonitoredResources/SET_IMPORTANCE_MULTI_SUCCESS'

export const DISABLE_MONITORING_MULTI =
  'app/MonitoredResources/DISABLE_MONITORING_MULTI'

export const DISABLE_MONITORING_MULTI_SUCCESS =
  'app/MonitoredResources/DISABLE_MONITORING_MULTI_SUCCESS'

export const SET_MONITORED_RESOURCES_ERROR =
  'app/MonitoredResources/SET_MONITORED_RESOURCES_ERROR'
