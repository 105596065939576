import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import _ from 'lodash'
import Tooltip from 'components/Tooltip'
import TextLink from 'components/TextLink'
import BorderlessButton from 'components/BorderlessButton'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import { fromJS } from 'immutable'
import Icon from 'components/Icon'
import { push } from 'connected-react-router'
import reducer from './reducer'
import saga from './sagas'
import {
  selectMonitoredResources,
  selectHasError,
  selectIsLoading,
  selectIsLoadingImportanceMulti,
  selectIsLoadingDisableMonitoringMulti,
} from './selectors'
import {
  getMonitoredResources,
  setImportanceMulti,
  disableMonitoringMulti,
} from './actions'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import permissionChecker from 'containers/PermissionChecker'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import BorderedCard from 'components/BorderedCard'
import DataTable from 'components/DataTable'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import { ToolbarItem } from 'components/BulkActionToolbar'
import SectionHeader from 'components/SectionHeader'
import { fetchTags } from 'containers/SonraiData/actions'
import {
  selectSwimlanes,
  selectTags,
  selectAccounts,
} from 'containers/SonraiData/selectors'
import MonitoredResourcesFilter from './MonitoredResourcesFilter'
import { exists } from 'utils/sonraiUtils'
import ChangeImportanceModal from 'containers/SearchResults/ChangeImportanceModal'
import SlideoutPanel from 'components/SlideoutPanel'
import NodeSolutionCenter from 'containers/NodeSolutionCenter'
import Error from 'components/Error'
class MonitoredResources extends Component {
  constructor(props) {
    super(props)

    this.hasPermission = props.userHasPermission({
      permissionName: 'view.data',
    })

    if (this.hasPermission) {
      if (props.tags.isEmpty()) {
        props.fetchTags()
      }

      props.getMonitoredResources({ filters: {} })
    }
    this.styles = {
      container: {
        display: 'grid',
        gridTemplateColumns: '100%',
        overflow: 'hidden',
        height: '100%',
      },
      containerWithPanel: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        gridTemplateAreas: "'results details'",
        overflow: 'hidden',
        height: '100%',
      },
      wrapper: {
        height: '100%',
        width: '100%',
        display: 'grid',
        padding: '1em',
        gridTemplateRows: 'auto 1fr',
        gridTemplateAreas: '"pagetitle" "content"',
      },
      tooltip: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px',
      },
      filters: {
        display: 'flex',
        alignItems: 'center',
      },
      loading: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    }
    this.state = {
      createdDate: undefined,
      cloud: undefined,
      account: undefined,
      tags: undefined,
      selectedResources: [],
      isImportanceModalOpen: false,
      showPanel: false,
      type: null,
      nodeId: null,
    }
  }

  updateData = () => {
    this.setState({
      selectedResources: [],
    })
    this.props.getMonitoredResources({ filters: {} })
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.isLoadingImportanceMulti &&
        !this.props.isLoadingImportanceMulti) ||
      (prevProps.isLoadingDisableMonitoringMulti &&
        !this.props.isLoadingDisableMonitoringMulti)
    ) {
      this.updateData()
    }
  }

  handleRowDoubleClick = (srn, type) => {
    let nav = getNodeViewPushParams(srn, type)
    nav.search = `${nav.search}&alert=true`

    this.props.push(nav)
  }

  goToSelectedNodeView = () => {
    const { nodeId, type } = this.state
    let nav = getNodeViewPushParams(nodeId, type)
    nav.search = `${nav.search}&alert=true`
    return nav
  }

  getHelpIcon = () => (
    <div style={{ padding: '2px', marginLeft: '0.4rem' }}>
      <Tooltip
        trigger={'hover'}
        anchor={
          <BorderlessButton>
            <Icon
              fa
              name="help"
              color={this.props.theme.neutralMedium}
              style={{ fontSize: '19px' }}
            />
          </BorderlessButton>
        }
        tooltipContent={
          <div style={this.styles.tooltip}>
            <span>
              These are the critical resources you have defined to have change
              detections recorded for. To add additional resources to the list,
              build or use a search result to find identities and data
              resources. Then "Enable Monitoring" or add "Change Detection" from
              the top right corner of the node view results after clicking on a
              result in the search.
            </span>
          </div>
        }
        position="right"
      />
    </div>
  )

  handleSlideOut = (nodeId, type) => {
    this.setState({
      nodeId,
      type,
      showPanel: true,
    })
  }

  refetchResources = filters => {
    let newFilters = {}
    _.keys(filters).forEach(key => {
      if (exists(filters[key])) {
        newFilters[key] = filters[key]
      }
    })

    this.props.getMonitoredResources({
      filters: newFilters,
    })
  }

  handleSelectionChange = evnt => {
    this.setState({
      selectedResources: evnt.api.getSelectedRows().map(row => row.srn),
    })
  }

  setFilter = filter => {
    this.setState({
      [filter.filterType]: filter.value || undefined,
    })
  }

  clearFilters = () => {
    this.setState({
      createdDate: undefined,
      cloud: undefined,
      account: undefined,
      tags: undefined,
    })
    this.refetchResources({})
  }

  toggleChangeImportanceModal = () => {
    this.setState(prevState => {
      return { isImportanceModalOpen: !prevState.isImportanceModalOpen }
    })
  }

  changeImportanceMulti = level => {
    this.props.setImportanceMulti({
      level: level,
      srns: this.state.selectedResources.map(srn => srn),
    })
  }

  handleMultiDisableMonitoring = () =>
    this.props.disableMonitoringMulti(this.state.selectedResources)

  render() {
    if (!this.hasPermission) {
      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {' '}
          Insufficent Permissions to view Monitored Resources{' '}
        </div>
      )
    }

    if (this.props.errorMessage) {
      return (
        <Error
          title={'There was an error loading the resources'}
          description={this.props.errorMessage}
        />
      )
    }

    const canEdit = this.props.userHasPermission({
      permissionName: 'edit.crmsettings',
    })

    return (
      <div
        style={
          !this.state.showPanel
            ? this.styles.container
            : this.styles.containerWithPanel
        }
      >
        <div style={this.styles.wrapper}>
          <div style={{ display: 'flex', paddingBottom: '1rem' }}>
            <SectionHeader>All Monitored Resources</SectionHeader>
            {this.getHelpIcon()}
          </div>
          <BorderedCard style={{ height: '100%' }}>
            {this.props.isLoading ? (
              <div style={this.styles.loading}>
                <SquareLoadingAnimation />
              </div>
            ) : (
              <DataTable
                data={this.props.monitoredResources.toJS()}
                checkboxSelectedRows={this.state.selectedResources}
                crm
                hasCheckBoxes={canEdit}
                onClickNodeView={this.handleRowDoubleClick}
                onSingleClickNodeView={this.handleSlideOut}
                selectionChanged={this.handleSelectionChange}
                bulkActionWorking={
                  this.props.isLoadingDisableMonitoringMulti ||
                  this.props.isLoadingImportanceMulti
                    ? true
                    : false
                }
                bulkActions={[
                  <ToolbarItem
                    permissionName="edit.crmsettings"
                    key="disable"
                    onClick={this.handleMultiDisableMonitoring}
                    iconName="ban"
                    disabled={
                      this.state.selectedResources.length === 0 ||
                      this.props.isLoadingDisableMonitoringMulti
                    }
                  >
                    Disable Monitoring
                  </ToolbarItem>,
                  <ToolbarItem
                    permissionName="edit.crmsettings"
                    key="importance"
                    iconName={'alert'}
                    onClick={this.toggleChangeImportanceModal}
                    disabled={
                      this.state.selectedResources.length === 0 ||
                      this.props.isLoadingImportanceMulti
                    }
                  >
                    Change Importance
                  </ToolbarItem>,
                ]}
                customFilter={() => (
                  <div style={this.styles.filters}>
                    <MonitoredResourcesFilter
                      refetchResources={this.refetchResources}
                      filters={fromJS(this.state)}
                      setFilter={this.setFilter}
                      swimlanes={this.props.swimlanes.toList()}
                      accounts={this.props.accounts}
                      tags={this.props.tags}
                      clearFilters={this.clearFilters}
                    />
                  </div>
                )}
              />
            )}
          </BorderedCard>
          <ChangeImportanceModal
            isOpen={this.state.isImportanceModalOpen}
            centered={true}
            toggle={this.toggleChangeImportanceModal}
            setImportance={this.changeImportanceMulti}
            isLoading={this.props.isLoadingImportanceMulti}
          />
        </div>
        <SlideoutPanel
          visible={this.state.showPanel}
          toggleVisible={() => this.setState({ showPanel: false })}
          actions={
            <TextLink color="primary" to={this.goToSelectedNodeView}>
              Go to full node view
            </TextLink>
          }
        >
          {this.state.showPanel && (
            <NodeSolutionCenter
              type={this.state.type}
              nodeId={this.state.nodeId}
              horizontal
            />
          )}
        </SlideoutPanel>
      </div>
    )
  }
}

MonitoredResources.propTypes = {
  theme: themeShape,
  getMonitoredResources: PropTypes.func.isRequired,
  monitoredResources: ImmutablePropTypes.iterable,
  isLoading: PropTypes.bool,
  push: PropTypes.func.isRequired,
  swimlanes: ImmutablePropTypes.iterable,
  accounts: ImmutablePropTypes.iterable,
  tags: ImmutablePropTypes.iterable,
  fetchTags: PropTypes.func,
  setImportanceMulti: PropTypes.func,
  isLoadingImportanceMulti: PropTypes.bool,
  disableMonitoringMulti: PropTypes.func,
  isLoadingDisableMonitoringMulti: PropTypes.bool,
  userHasPermission: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
}

const mapStateToProps = createStructuredSelector({
  errorMessage: selectHasError,
  monitoredResources: selectMonitoredResources,
  isLoading: selectIsLoading,
  swimlanes: selectSwimlanes,
  accounts: selectAccounts,
  tags: selectTags,
  isLoadingImportanceMulti: selectIsLoadingImportanceMulti,
  isLoadingDisableMonitoringMulti: selectIsLoadingDisableMonitoringMulti,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMonitoredResources,
      push,
      fetchTags,
      setImportanceMulti,
      disableMonitoringMulti,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({ key: 'monitoredResources', reducer })
const withSaga = injectSaga({ key: 'mR', saga: saga })

export default compose(
  withReducer,
  withSaga,
  withConnect,
  permissionChecker
)(themeable(MonitoredResources))
