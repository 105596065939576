import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Button from 'components/Button'
import Popover, { PopoverBody, PopoverAnchor } from 'components/Popover'
import Icon from 'components/Icon'
import Select from 'react-select'
import moment from 'moment'
import TextLink from 'components/TextLink'
import AccountSelector from 'components/AccountSelector'

const FILTERS_AVAILABLE = ['cloud', 'createdDate', 'account', 'tags']
export class MonitoredResourcesFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filterMenuExpanded: false,
      account: undefined,
      cloud: undefined,
      tags: undefined,
      createdDate: undefined,
    }

    this.styles = {
      filterList: {
        gridArea: 'filterList',
        paddingTop: '0.75em',
        paddingLeft: '1em',
        fontFamily: 'Roboto-Italic',
        fontSize: '14px',
        color: '#777777',
      },
      sort: {
        marginRight: '0.5em',
      },
      filter: {
        marginLeft: '0.5em',
      },
      filterTitle: {
        marginBottom: '0.5em',
        display: 'block',
      },
      filterHeader: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0.25rem 0.5rem 0.25rem 0.5rem',
      },
      filterPanelFooter: {
        width: '100%',
        margin: '1rem 0rem 0rem 0rem',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
    }
  }

  toggleFilterBubble = () => {
    this.setState(prevState => ({
      filterMenuExpanded: !prevState.filterMenuExpanded,
      cloud: undefined,
      createdDate: undefined,
      account: undefined,
    }))
  }

  handleTagFilterChange = value => {
    if (value === null || _.isEmpty(value)) {
      this.setState({
        tags: null,
      })
    } else {
      this.setState({
        tags: value,
      })
    }
  }

  handleAccountFilterChange = value => {
    if (value === null || _.isEmpty(value)) {
      this.setState({
        account: null,
      })
    } else {
      this.setState({
        account: value,
      })
    }
  }

  handleCloudFilterChange = value => {
    if (value === null || _.isEmpty(value)) {
      this.setState({
        cloud: null,
      })
    } else {
      this.setState({
        cloud: value,
      })
    }
  }

  handleCreatedDateFilter = value => {
    if (value) {
      const format = 'YYYY-MM-DD'
      let date = moment()
        .subtract(value.value, 'days')
        .format(format)
      this.setState({
        createdDate: { label: value.label, value: date },
      })
    } else {
      this.setState({
        createdDate: null,
      })
    }
  }

  applyFilters = () => {
    const currentFilters = this.props.filters.toJS()
    const filtersModified = FILTERS_AVAILABLE.filter(
      key => this.state[key] !== undefined
    )
    const finalFilters = {
      cloud: currentFilters.cloud,
      createdDate: currentFilters.createdDate,
      account: currentFilters.account,
      tags: currentFilters.tags,
    }
    filtersModified.forEach(filterType => {
      finalFilters[filterType] = this.state[filterType]
      this.props.setFilter({
        filterType,
        value: this.state[filterType],
      })
    })
    this.props.refetchResources(finalFilters)
    this.setState({
      filterMenuExpanded: false,
    })
  }

  filtersEmpty = () => {
    const filters = this.props.filters.toJS()

    const emptyFilters = FILTERS_AVAILABLE.map(filter => {
      if (filters[filter]) {
        if (Array.isArray(filters[filter])) {
          if (!_.isEmpty(filters[filter])) {
            return false
          }
        }
        return false
      }
      return true
    })
    return !emptyFilters.includes(false)
  }

  clearFilters = () => {
    this.toggleFilterBubble()
    this.props.clearFilters()
  }

  getAccountSelectorValue = () => {
    const filters = this.props.filters.toJS()
    if (this.state.account === undefined) {
      return filters.account
        ? filters.account.map(account => ({
            label: account.value || account,
            value: account.value || account,
          }))
        : filters.account
    } else {
      if (this.state.account === null) {
        return null
      } else {
        this.state.account.map(account => ({
          label: account.value || account,
          value: account.value || account,
        }))
      }
    }
  }

  isDisabled = () => false

  getTagOptions = () => {
    const serverTags = this.props.tags
      .filter(tag => !!tag.get('key'))
      .sortBy(tag => tag.get('key').toLowerCase())
      .toJS()
      .map(tag => ({ value: tag.key, label: tag.key }))

    const additionalOptions = this.state.tags
      ? this.state.tags.map(tagStr => ({
          value: tagStr,
          label: tagStr,
        }))
      : []

    return serverTags.concat(additionalOptions)
  }

  render() {
    const filters = this.props.filters.toJS()
    return (
      <Popover
        isOpen={this.state.filterMenuExpanded}
        onToggle={this.toggleFilterBubble}
      >
        <PopoverAnchor>
          {this.filtersEmpty() ? (
            <Icon style={this.styles.filter} fa name="filter" />
          ) : (
            <Icon
              style={{
                ...this.styles.filter,
                color: this.props.theme.emphasis,
              }}
              fa
              name="filter-solid"
            />
          )}
        </PopoverAnchor>
        <PopoverBody>
          <div style={{ width: '350px' }}>
            <div style={this.styles.filterHeader}>
              <div
                style={{
                  fontSize: '1.15rem',
                  fontWeight: '400',
                }}
              >
                Filters
              </div>
              <TextLink
                onClick={this.clearFilters}
                color="primary"
                style={{
                  cursor: 'pointer',
                }}
              >
                Clear All Filters
              </TextLink>
            </div>

            <div style={{ margin: '0.5em' }}>
              <span style={this.styles.filterTitle}>Created Date:</span>
              <Select
                placeholder="Created Date"
                onChange={this.handleCreatedDateFilter}
                value={
                  this.state.createdDate === undefined
                    ? filters.createdDate
                    : this.state.createdDate
                }
                isClearable
                options={[
                  { value: 1, label: 'Within the last 24 Hours' },
                  { value: 7, label: 'Within the last 7 Days' },
                  { value: 30, label: 'Within the last 30 Days' },
                  { value: 60, label: 'Within the last 60 Days' },
                  { value: 90, label: 'Within the last 90 Days' },
                ]}
              />
            </div>
            <div style={{ margin: '0.5em' }}>
              <span style={this.styles.filterTitle}>Accounts:</span>
              <AccountSelector
                value={this.getAccountSelectorValue()}
                isMulti
                onChange={this.handleAccountFilterChange}
                accounts={this.props.accounts}
              />
            </div>
            <div style={{ margin: '0.5em' }}>
              <span style={this.styles.filterTitle}>Clouds:</span>
              <Select
                placeholder="Cloud Filter"
                onChange={this.handleCloudFilterChange}
                isMulti
                value={
                  this.state.cloud === undefined
                    ? filters.cloud
                    : this.state.cloud
                }
                options={[
                  { value: 'aws', label: 'AWS' },
                  { value: 'azure', label: 'Azure' },
                  { value: 'gcp', label: 'GCP' },
                ]}
              />
            </div>
            <div style={{ margin: '0.5em ' }}>
              <span style={this.styles.filterTitle}>Tags:</span>
              <Select
                placeholder="Tags"
                onChange={this.handleTagFilterChange}
                isMulti
                value={
                  this.state.tags === undefined ? filters.tags : this.state.tags
                }
                options={this.getTagOptions()}
              />
            </div>
          </div>
          <div style={this.styles.filterPanelFooter}>
            <TextLink
              onClick={this.toggleFilterBubble}
              color="primary"
              style={{
                cursor: 'pointer',
                paddingRight: '1rem',
              }}
            >
              Cancel
            </TextLink>
            <Button
              color="primary"
              disabled={this.isDisabled()}
              onClick={this.applyFilters}
            >
              Apply
            </Button>
          </div>
        </PopoverBody>
      </Popover>
    )
  }
}

MonitoredResourcesFilter.propTypes = {
  filters: ImmutablePropTypes.map,
  clearFilters: PropTypes.func,
  setFilter: PropTypes.func.isRequired,
  refetchResources: PropTypes.func.isRequired,
  accounts: ImmutablePropTypes.iterable,
  tags: ImmutablePropTypes.iterable,
  theme: themeShape,
}

export default themeable(MonitoredResourcesFilter)
