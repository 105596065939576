import { createSelector } from 'reselect'
import { Map, List } from 'immutable'

export const selectMonitoredResourcesDomain = state =>
  state.get('monitoredResources', Map()) || Map()

export const selectMonitoredResources = createSelector(
  selectMonitoredResourcesDomain,
  data => data.get('monitoredResources') || List()
)

export const selectIsLoading = createSelector(
  selectMonitoredResourcesDomain,
  data => data.get('isLoading')
)

export const selectIsLoadingImportanceMulti = createSelector(
  selectMonitoredResourcesDomain,
  data => data.get('isLoadingImportanceMulti')
)

export const selectIsLoadingDisableMonitoringMulti = createSelector(
  selectMonitoredResourcesDomain,
  data => data.get('isLoadingDisableMonitoringMulti')
)

export const selectHasError = createSelector(
  selectMonitoredResourcesDomain,
  data => data.get('error')
)
