import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import Creatable from 'react-select/creatable'
import AsyncSelect from 'react-select/async'

import CloudAccount from 'components/CloudAccount'
import { getAccountIdFromAccountSrn } from 'utils/sonraiUtils'

const triggerAsyncLimit = 100
const asyncSlice = 100

class AccountSelector extends React.Component {
  constructor(props) {
    super(props)
    if (props.accounts.size > triggerAsyncLimit) {
      this.state = {
        enableAsync: true,
        accountOptions: props.accounts
          .toJS()
          .map(account => ({
            label: this.getAccountDisplayName(account),
            value: getAccountIdFromAccountSrn(account.srn),
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
          .slice(0, asyncSlice),
      }
    } else {
      this.state = {
        enableAsync: false,
      }
    }
  }

  getAccountForAccountId = id => {
    return this.props.accounts
      .find(a => a.get('srn').endsWith(id), null, Map())
      .toJS()
  }

  getAccountDisplayName = account => {
    return (
      account.friendlyName ||
      account.name ||
      getAccountIdFromAccountSrn(account.srn) ||
      account.srn
    )
  }

  getAccountBadge = accountId => {
    const account = this.getAccountForAccountId(accountId.toLowerCase())
    if (!account.srn) {
      return accountId
    }

    return (
      <CloudAccount
        hasTooltip
        accountSrn={account.srn}
        hasAccountNumber={this.props.hasAccountNumber}
      />
    )
  }

  filterOption = ({ data }, inputValue) => {
    const lower = inputValue.toLowerCase()

    return (
      data.label.toLowerCase().includes(lower) ||
      data.value.toLowerCase().includes(lower)
    )
  }

  onChange = selectedOptions => {
    if (!selectedOptions) {
      this.props.onChange([])
      return
    }

    this.props.onChange(selectedOptions.map(opt => opt.value))
  }

  getNewOptionData = inputValue => {
    return { value: inputValue, label: inputValue }
  }

  loadOptions = (inputValue, callback) => {
    callback(
      this.props.accounts
        .toJS()
        .map(account => ({
          label: this.getAccountDisplayName(account),
          value: getAccountIdFromAccountSrn(account.srn),
        }))
        .filter(
          i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase()) ||
            i.value.toLowerCase().includes(inputValue.toLowerCase())
        )
        .slice(0, asyncSlice)
        .sort((a, b) => a.label.localeCompare(b.label))
    )
  }

  render() {
    // if there are over triggerAsyncLimit accounts we can load the results async... or adjust this later
    if (this.state.enableAsync) {
      return (
        <AsyncSelect
          cacheOptions
          loadOptions={this.loadOptions}
          defaultOptions
          isLoading={this.props.accounts.isEmpty()}
          onChange={this.onChange}
          options={this.state.accountOptions}
          getOptionLabel={option => this.getAccountBadge(option.value)}
          isClearable
          creatable
          value={this.props.value}
          styles={this.props.styles}
          isMulti={this.props.isMulti}
          isDisabled={this.props.disabled}
        />
      )
    }
    // under triggerAsyncLimit just do yo thang
    const accountOptions = this.props.accounts
      .toJS()
      .map(account => ({
        label: this.getAccountDisplayName(account),
        value: getAccountIdFromAccountSrn(account.srn),
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    return (
      <Creatable
        isMulti={this.props.isMulti}
        isLoading={this.props.accounts.isEmpty()}
        options={accountOptions}
        value={this.props.value}
        onChange={this.onChange}
        creatable
        getOptionValue={option => option.value}
        getOptionLabel={option => this.getAccountBadge(option.value)}
        filterOption={this.filterOption}
        getNewOptionData={this.getNewOptionData}
        isClearable
        styles={this.props.styles}
        isDisabled={this.props.disabled}
      />
    )
  }
}

AccountSelector.defaultProps = {
  isMulti: true,
}

AccountSelector.propTypes = {
  accounts: ImmutablePropTypes.iterable.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  styles: PropTypes.object,
  disabled: PropTypes.bool,
  hasAccountNumber: PropTypes.bool,
}
export default AccountSelector
