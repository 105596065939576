import { createAction } from 'redux-actions'
import {
  GET_MONITORED_RESOURCES,
  SET_MONITORED_RESOURCES,
  SET_IMPORTANCE_MULTI,
  SET_IMPORTANCE_MULTI_SUCCESS,
  DISABLE_MONITORING_MULTI,
  DISABLE_MONITORING_MULTI_SUCCESS,
  SET_MONITORED_RESOURCES_ERROR,
} from './constants'

export const getMonitoredResources = createAction(GET_MONITORED_RESOURCES)
export const setMonitoredResources = createAction(SET_MONITORED_RESOURCES)
export const setImportanceMulti = createAction(SET_IMPORTANCE_MULTI)
export const setImportanceMultiSuccess = createAction(
  SET_IMPORTANCE_MULTI_SUCCESS
)

export const disableMonitoringMulti = createAction(DISABLE_MONITORING_MULTI)
export const disableMonitoringMultiSuccess = createAction(
  DISABLE_MONITORING_MULTI_SUCCESS
)
export const setMonitoredResourcesError = createAction(
  SET_MONITORED_RESOURCES_ERROR
)
