import { all, put, takeLatest } from 'redux-saga/effects'
import gql from 'graphql-tag'
import { getClient } from 'apolloClient'
import {
  setMonitoredResources,
  setMonitoredResourcesError,
  setImportanceMultiSuccess,
  disableMonitoringMultiSuccess,
} from './actions'
import {
  GET_MONITORED_RESOURCES,
  SET_IMPORTANCE_MULTI,
  DISABLE_MONITORING_MULTI,
} from './constants'

function* getMonitoredResources({ payload }) {
  const client = getClient()

  const { filters = {} } = payload
  let and = []
  if (filters.createdDate && filters.createdDate.value) {
    and.push(
      `{ createdDate: { op: GT  value: "${filters.createdDate.value}"} }`
    )
  }

  if (filters.account && filters.account.length >= 1) {
    and.push(`{ account: { op: IN_LIST values: [${filters.account
      .map(account => `"${account}"`)
      .join(', ')}]
    }}`)
  }

  if (filters.cloud && filters.cloud.length === 1) {
    and.push(`{
          cloudType:{
          op: EQ
          value: "${filters.cloud[0].value}"
          }}`)
  } else if (filters.cloud && filters.cloud.length > 1) {
    and.push(`{
          or: [
            ${filters.cloud
              .map(
                cloud => `{
              cloudType:{
              op: EQ
              value: "${cloud.value}"
              }}`
              )
              .join(', ')}
          ]
        }`)
  }
  if (filters.tags && filters.tags.length === 1) {
    and.push(`{
          tagSet:{
          op: CONTAINS
          value: "${filters.tags[0].value}"
          }}`)
  } else if (filters.tags && filters.tags.length > 1) {
    and.push(`{
          or: [
            ${filters.tags
              .map(
                tag => `{
              tagSet:{
              op: CONTAINS
              value: "${tag.value}"
              }}`
              )
              .join(', ')}
          ]
        }`)
  }

  try {
    const result = yield client.query({
      forceFetch: true,
      fetchPolicy: 'no-cache',
      query: gql`
          query getAllMonitoredResources{
            Resources (
              where: {
                and: [
                  {
                    sonraiConfig:{
                      value: MONITORED
                    }
                  },
                  ${and.join(', ')}
                ]
              }
            ) {
              items {
                sonraiConfig,
                name,
                account,
                cloudType,
                lastAlertDate,
                importance,
                region,
                label,
                friendlyName,
                country,
                highestAlertSeverity,
                srn,
                active,
              }
            }
          }
        `,
    })

    if (result.data.Resources && result.data.Resources.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    const resources = result.data.Resources.items.sort((a, b) =>
      a.highestAlertSeverity < b.highestAlertSeverity
        ? 1
        : b.highestAlertSeverity < a.highestAlertSeverity
        ? -1
        : 0
    )

    yield put(setMonitoredResources(resources))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error getting monitored resources ', e)
    yield put(setMonitoredResourcesError(e.message))
  }
}

function* setImportanceMulti(action) {
  const { srns, level } = action.payload
  let queryString = 'mutation setImportance {'
  let i = 0
  srns.forEach(srn => {
    queryString += `
      s${i}: setImportance(srn: "${srn}", importance: ${level}) {
        srn
        importance
      }
    `
    i++
  })
  queryString += `
    }
  `

  try {
    const client = getClient()
    yield client.mutate({
      mutation: gql`
        ${queryString}
      `,
    })

    yield put(setImportanceMultiSuccess())
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error setting importance', e)
  }
}

function* disableMonitoringMulti(action) {
  try {
    const client = getClient()
    yield all(
      action.payload.map(srn => {
        return client.mutate({
          mutation: gql`
          mutation toggleResourceMonitoring {
            setMonitor(monitorStatusBySrn: {srn: "${srn}", monitor: false }){
              srn
              monitor
            }
          }
        `,
        })
      })
    )
    yield put(disableMonitoringMultiSuccess())
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('Error disabling monitoring', e)
  }
}

function* monitoredResourcesSaga() {
  yield all([
    takeLatest(GET_MONITORED_RESOURCES, getMonitoredResources),
    takeLatest(SET_IMPORTANCE_MULTI, setImportanceMulti),
    takeLatest(DISABLE_MONITORING_MULTI, disableMonitoringMulti),
  ])
}

export default monitoredResourcesSaga
