import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Select from 'react-select'
import TextLink from 'components/TextLink'
import Button from 'components/Button'

const importanceLevels = 10

export class ChangeImportanceModal extends React.Component {
  state = {
    importance: null,
  }

  handleChange = newImportance => {
    this.setState({ importance: newImportance })
  }

  isDisabled = () => {
    return this.state.importance === null || this.props.isLoading
  }

  close = () => {
    this.setState({ importance: null })
    this.props.toggle()
  }

  setImportance = () => {
    this.props.setImportance(this.state.importance.value)
    this.props.toggle()
  }

  render() {
    const options = Array.from(
      { length: importanceLevels },
      (value, key) => key
    ).map(num => {
      return { label: num + 1, value: num + 1 }
    })
    return (
      <Modal isOpen={this.props.isOpen}>
        <ModalHeader>Change importance</ModalHeader>
        <ModalBody>
          <Select
            value={this.state.importance}
            onChange={this.handleChange}
            options={options}
            placeholder={'Select level of importance...'}
          />
        </ModalBody>
        <ModalFooter>
          <TextLink color="secondary" onClick={this.close}>
            Cancel
          </TextLink>
          <Button
            onClick={this.setImportance}
            color="primary"
            disabled={this.isDisabled()}
          >
            Change
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

ChangeImportanceModal.propTypes = {
  isOpen: PropTypes.bool,
  setImportance: PropTypes.func.isRequired,
  isLoading: PropTypes.func,
  toggle: PropTypes.func,
}

export default ChangeImportanceModal
