/*
 *
 * MonitoredResources reducer
 *
 */

import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'

import {
  GET_MONITORED_RESOURCES,
  SET_MONITORED_RESOURCES,
  SET_IMPORTANCE_MULTI,
  SET_IMPORTANCE_MULTI_SUCCESS,
  DISABLE_MONITORING_MULTI,
  DISABLE_MONITORING_MULTI_SUCCESS,
  SET_MONITORED_RESOURCES_ERROR,
} from './constants'

const initialState = fromJS({
  isLoading: false,
  monitoredResources: [],
  error: null,
  isLoadingImportanceMulti: false,
  isLoadingDisableMonitoringMulti: false,
})

const monitoredResources = handleActions(
  {
    [GET_MONITORED_RESOURCES]: state =>
      state.set('isLoading', true).set('error', null),
    [SET_MONITORED_RESOURCES]: (state, { payload }) =>
      state.set('isLoading', false).set('monitoredResources', fromJS(payload)),
    [SET_IMPORTANCE_MULTI]: state =>
      state.set('isLoadingImportanceMulti', true),
    [SET_IMPORTANCE_MULTI_SUCCESS]: state =>
      state.set('isLoadingImportanceMulti', false),
    [DISABLE_MONITORING_MULTI]: state =>
      state.set('isLoadingDisableMonitoringMulti', true),
    [DISABLE_MONITORING_MULTI_SUCCESS]: state =>
      state.set('isLoadingDisableMonitoringMulti', false),
    [SET_MONITORED_RESOURCES_ERROR]: (state, { payload }) =>
      state.set('isLoading', false).set('error', payload),
  },

  initialState
)

export default monitoredResources
